<template>
  <div class="main-content">
    <avue-form ref="form" :value="form" :option="formOption">
      <template slot="picture">
        <FormImgUpload
          :url="form.picture"
          @upload="upload"
          :limit="1"
          :maxSize="2"
          accept=".png,.jpg"
          :multiple="true"
          :disabled="false"
        />
        <div style="color: #FF001F">
          建议尺寸：375px*642px，图片格式：.png，.jpg
        </div>
      </template>

      <template slot="content">
        <FormEditor
          :details="form.content"
          @editoChange="editoChange"
          :disabled="false"
        />
      </template>

      <template slot="menuForm">
        <!-- <el-button @click="handleClose">取消</el-button> -->
        <el-button type="primary" :loading="showLoading" @click="submit(form)"
          >保存</el-button
        >
      </template>
    </avue-form>
  </div>
</template>

<script>
import FormEditor from "@/components/formComponents/formEditor";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import {
  activityAddActivity,
  activityActivityView,
} from "@/api/activity/active";
export default {
  name: "createThree",
  components: {
    FormEditor,
    FormImgUpload,
  },
  data() {
    return {
      form: {
        content: "",
        picture: [],
      },
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "列表页图片",
            prop: "picture",
            type: "text",
            maxlength: 50,
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "活动内容",
            prop: "content",
            type: "text",
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              {
                validator(_, value) {
                  if (value) {
                    if (value === "<p><br></p>") {
                      return Promise.reject(new Error("请输入内容"));
                    }
                    if (value.length > 65535) {
                      return Promise.reject(new Error("内容超出最大长度限制"));
                    }
                  }
                  return Promise.resolve(true);
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    //初始化
    onLoad() {
      this.showLoading = true;
      activityActivityView().then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.form = {
            ...res.data,
            content: res.data.content || "",
            picture: res.data.picture ? [res.data.picture] : [],
          };
        }
      });
    },
    async setForm(form) {
      this.form = form;
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //图片上传
    upload(e) {
      console.log(e, "eeeeeeeee");
      if (Array.isArray(e)) {
        this.form.picture = [...e];
      } else {
        this.form.picture = [...this.form.picture, e];
      }
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          console.log(form, "formformform");
          this.showLoading = true;

          activityAddActivity({
            ...this.form,
            content: this.form.content,
            picture: this.form.picture[0],
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.onLoad();
                this.showLoading = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoading = false;
                done();
              }
            })
            .catch(() => {
              this.showLoading = false;
            });
          // done()
        }
      });
    },
  },
};
</script>

<style scoped></style>
